import { render, staticRenderFns } from "./RegistrationsTable.vue?vue&type=template&id=a96900d0&scoped=true&"
import script from "./RegistrationsTable.vue?vue&type=script&lang=js&"
export * from "./RegistrationsTable.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationsTable.vue?vue&type=style&index=0&id=a96900d0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a96900d0",
  null
  
)

export default component.exports