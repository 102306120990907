import { render, staticRenderFns } from "./VendorsPage.vue?vue&type=template&id=390c21e7&scoped=true&"
import script from "./VendorsPage.vue?vue&type=script&lang=js&"
export * from "./VendorsPage.vue?vue&type=script&lang=js&"
import style0 from "./VendorsPage.vue?vue&type=style&index=0&id=390c21e7&prod&lang=scss&scoped=true&"
import style1 from "./VendorsPage.vue?vue&type=style&index=1&id=390c21e7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "390c21e7",
  null
  
)

export default component.exports